<template>
  <div>
    <topbar :title="title"></topbar>
    <div class="cover">
      <img src="@/assets/icon/banner.png" alt="" />
    </div>
    <div class="content">{{ content }}</div>
  </div>
</template>
<script>
import Topbar from "../../components/Topbar.vue";
export default {
  name: "InfoDetail",
  data() {
    return {
      title: "消息標題",
      content:"正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文正文",
    };
  },
  components: {
    Topbar,
  },
};
</script>
<style scoped>
.cover {
  width: 100%;
  height: 2.21rem;
}
.cover img {
  width: 100%;
  object-fit: cover;
}
.content {
  padding: 0.16rem;
  box-sizing: border-box;
  font-size: .14rem;
  font-family: PingFang HK;
  font-weight: 400;
  line-height: .18rem;
  color: #03060d;
  text-align: justify;
  white-space: pre-line;
}
</style>